/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'thumbs_up-full': {
    width: 96,
    height: 96,
    viewBox: '0 0 96 96',
    data: '<path pid="0" _fill="#111" d="M30.24 12.02v.01c.06 0 .12 0 .17.01.11 0 .22-.01.33 0 4.27.11 8.18 1.68 11.73 4.08.07.05.14.12.21.17.48.37.96.75 1.42 1.16.12.11.25.22.37.34.13.12.26.23.38.35.16.15.29.32.44.48.22.21.44.41.65.64.13.15.25.31.38.46.12.14.23.27.34.41.17.2.34.4.5.6.15.21.3.41.45.62.12.16.22.32.33.48.02.03.05.06.07.09.02-.03.05-.06.07-.09l.33-.48c.15-.21.3-.41.45-.62.16-.2.34-.4.5-.6.11-.14.23-.27.34-.41.13-.15.25-.31.39-.46.2-.23.43-.43.64-.64.15-.16.28-.33.44-.48.12-.12.25-.23.38-.35l.37-.34c.47-.41.95-.81 1.45-1.18.06-.04.12-.1.18-.15.17-.12.35-.23.52-.35l.36-.24c4.1-2.77 8.93-3.74 13.57-3.4h.05c.24.03.49.05.73.08.17.02.33.06.49.09.1.01.19.03.29.05.32.05.63.11.94.18.08.02.16.03.23.05l.17.04c.03.01.06.01.09.02 1.05.26 2.08.6 3.08 1.02 2.91 1.24 5.55 3.14 7.73 5.54v-.01c.68.74 1.3 1.53 1.88 2.36.36.51.69 1.03 1 1.57l.12.21c.12.2.24.4.35.61.15.28.28.56.41.84 2.934 5.99 3.113 12.81 1.17 19.12-.079.23-.175.46-.261.69-.116.31-.228.62-.356.92-.031.08-.057.16-.09.23a24.172 24.172 0 01-2.733 4.75c-.36.49-.74.95-1.14 1.41-.18.21-.36.42-.54.62l-.01.01-10.17 10.92-6.85 7.35h.01l-15.6 16.75c-.13.12-.16.16-.31.25-.11.06-.22.11-.34.14-.11.03-.22.04-.34.04-.11 0-.22-.01-.33-.04s-.22-.08-.33-.14c-.15-.09-.18-.13-.31-.25L31.43 70.8 14.41 52.53c0-.01 0-.01-.01-.01-.17-.2-.35-.4-.52-.6l-.01-.02h-.01c-.39-.46-.78-.92-1.14-1.41-1.08-1.46-2-3.05-2.73-4.75-.04-.08-.07-.17-.1-.26-.07-.17-.14-.35-.2-.52-2.55-6.5-2.15-13.62.62-19.9.05-.09.1-.19.14-.28.14-.28.27-.56.42-.84.1-.2.22-.39.33-.59.05-.08.1-.17.15-.25a23.927 23.927 0 012.86-3.91l.01.01c2.18-2.4 4.82-4.3 7.73-5.54 1-.42 2.03-.76 3.08-1.02l.09-.03.16-.04c.08-.01.16-.02.24-.04.31-.07.62-.13.93-.19.1-.01.2-.03.3-.05.16-.02.32-.06.48-.08.25-.03.49-.06.74-.08h.04c.44-.05.88-.07 1.32-.09h.05c.13-.01.26-.02.38-.02z"/>'
  }
})
