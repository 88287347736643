/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'double-next': {
    width: 96,
    height: 96,
    viewBox: '0 0 96 96',
    data: '<path pid="0" d="M43.552 2.164c4.145 5.244 8.128 10.541 11.791 15.938 4.076 6.003 7.726 11.828 10.954 17.47 2.978 5.208 5.22 9.642 7 13.59-1.78 3.95-4.021 8.386-7 13.595-3.228 5.642-6.878 11.467-10.954 17.47-3.662 5.396-7.646 10.692-11.791 15.937v-7.936c2.4-3.15 4.768-6.378 7.075-9.752a197.076 197.076 0 008.22-13.085c2.6-4.527 5.706-10.747 7.715-15.003v-.002l.575-1.221-.575-1.222v-.002c-2.009-4.257-5.114-10.477-7.715-15.002a197.265 197.265 0 00-8.22-13.086 234.847 234.847 0 00-7.075-9.751V2.164z" _fill="gray"/><path pid="1" d="M21.624 1.61c4.145 5.244 8.128 10.541 11.792 15.938 4.075 6.003 7.725 11.828 10.953 17.47 2.979 5.208 5.22 9.642 7 13.59-1.78 3.95-4.02 8.386-7 13.595-3.228 5.642-6.878 11.467-10.953 17.47-3.663 5.396-7.647 10.692-11.792 15.937v-7.936c2.4-3.15 4.768-6.379 7.075-9.752a197.076 197.076 0 008.22-13.085c2.6-4.527 5.706-10.747 7.715-15.003v-.002l.575-1.221-.575-1.222v-.002c-2.009-4.257-5.114-10.477-7.715-15.003a197.265 197.265 0 00-8.22-13.085 234.847 234.847 0 00-7.075-9.751V1.61z" _fill="gray"/>'
  }
})
