/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'navigation-menu': {
    width: 96,
    height: 96,
    viewBox: '0 0 96 96',
    data: '<g color="#000" _fill="gray"><path pid="0" style="marker:none" d="M12 21v4h72v-4zM12 46v4h72v-4zM12 71v4h72v-4z" overflow="visible"/></g>'
  }
})
