/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'close': {
    width: 96,
    height: 96,
    viewBox: '0 0 96 96',
    data: '<g color="#000" font-weight="400" font-family="sans-serif" _fill="gray"><path pid="0" style="line-height:normal;font-variant-ligatures:none;font-variant-position:normal;font-variant-caps:normal;font-variant-numeric:normal;font-variant-alternates:normal;font-feature-settings:normal;text-indent:0;text-align:start;text-decoration-line:none;text-decoration-style:solid;text-decoration-color:#000;text-transform:none;block-progression:tb;white-space:normal;shape-padding:0;isolation:auto;mix-blend-mode:normal;solid-color:#000;solid-opacity:1;marker:none" d="M84 14.829L14.83 83.999l-2.828-2.827 69.17-69.17L84 14.829z" overflow="visible"/><path pid="1" style="line-height:normal;font-variant-ligatures:none;font-variant-position:normal;font-variant-caps:normal;font-variant-numeric:normal;font-variant-alternates:normal;font-feature-settings:normal;text-indent:0;text-align:start;text-decoration-line:none;text-decoration-style:solid;text-decoration-color:#000;text-transform:none;block-progression:tb;white-space:normal;shape-padding:0;isolation:auto;mix-blend-mode:normal;solid-color:#000;solid-opacity:1;marker:none" d="M84 81.172l-2.83 2.827L12 14.829l2.829-2.827 69.172 69.17z" overflow="visible"/></g>'
  }
})
