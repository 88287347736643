/* eslint-disable */
require('./back')
require('./buggy-full')
require('./buggy')
require('./close')
require('./double-back')
require('./double-next')
require('./external-link')
require('./github')
require('./gitlab')
require('./happy-full')
require('./happy')
require('./info')
require('./lock')
require('./navigation-menu')
require('./neutral-full')
require('./neutral')
require('./next')
require('./note')
require('./security-alert')
require('./spinner')
require('./thumbs_down-full')
require('./thumbs_down')
require('./thumbs_up-full')
require('./thumbs_up')
require('./toolkit_arrow-down')
require('./toolkit_arrow-up')
require('./ubuntuone')
require('./user-admin')
