/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'double-back': {
    width: 96,
    height: 96,
    viewBox: '0 0 96 96',
    data: '<path pid="0" d="M51.37 2.164c-4.145 5.244-8.128 10.541-11.792 15.938-4.076 6.003-7.726 11.828-10.953 17.47-2.979 5.208-5.221 9.642-7 13.59 1.779 3.95 4.02 8.386 7 13.595 3.227 5.642 6.877 11.467 10.953 17.47 3.663 5.396 7.647 10.692 11.792 15.937v-7.936c-2.4-3.15-4.769-6.378-7.076-9.752a197.076 197.076 0 01-8.22-13.085c-2.6-4.527-5.705-10.747-7.714-15.003v-.002l-.575-1.221.575-1.222v-.002c2.009-4.257 5.114-10.477 7.715-15.002a197.265 197.265 0 018.22-13.086 234.847 234.847 0 017.075-9.751V2.164z" _fill="gray"/><path pid="1" d="M73.297 1.61C69.152 6.854 65.17 12.15 61.506 17.548c-4.076 6.003-7.726 11.828-10.954 17.47-2.978 5.208-5.22 9.642-7 13.59 1.78 3.95 4.021 8.386 7 13.595 3.228 5.642 6.878 11.467 10.954 17.47 3.663 5.396 7.646 10.692 11.791 15.937v-7.936c-2.4-3.15-4.768-6.379-7.075-9.752a197.076 197.076 0 01-8.22-13.085c-2.6-4.527-5.706-10.747-7.714-15.003v-.002l-.576-1.221.576-1.222v-.002c2.008-4.257 5.113-10.477 7.715-15.003a197.265 197.265 0 018.22-13.085 234.847 234.847 0 017.074-9.751V1.61z" _fill="gray"/>'
  }
})
