/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'happy': {
    width: 96,
    height: 96,
    viewBox: '0 0 96 96',
    data: '<path pid="0" d="M29.45 58.23s7.566 9.766 18.61 9.766c11.05 0 18.42-9.964 18.42-9.964" _stroke="#111" stroke-linecap="round" stroke-width="4" _fill="none"/><path pid="1" d="M54.87 37.21q0-2.021 1.405-3.392 1.405-1.405 3.392-1.405t3.392 1.405q1.439 1.37 1.439 3.392 0 1.987-1.439 3.426-1.405 1.405-3.392 1.405-2.021 0-3.426-1.405-1.37-1.439-1.37-3.426zM31.48 37.21q0-2.021 1.405-3.392 1.405-1.405 3.392-1.405t3.392 1.405q1.439 1.37 1.439 3.392 0 1.987-1.439 3.426-1.405 1.405-3.392 1.405-2.021 0-3.426-1.405-1.37-1.439-1.37-3.426z" _fill="#111"/><circle pid="2" _stroke="#111" cy="48" cx="47.99" stroke-width="4" _fill="none" r="33.97"/>'
  }
})
