/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'note': {
    width: 96,
    height: 96,
    viewBox: '0 0 96 96',
    data: '<g color="#000" _fill="gray"><path pid="0" style="marker:none" d="M8 8v80h59l21-21V8H8zm4 4h72v52H64v20H12V12z" overflow="visible"/><path pid="1" style="marker:none" overflow="visible" d="M26 28h44v4H26zM26 40h44v4H26zM26 52h25.143v4H26z"/></g>'
  }
})
