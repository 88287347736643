/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'info': {
    width: 96,
    height: 96,
    viewBox: '0 0 96 96',
    data: '<g transform="matrix(0 -.9996 -1 0 441.362 437.83)" color="#000" _fill="gray"><path pid="0" style="line-height:normal;font-variant-ligatures:none;font-variant-position:normal;font-variant-caps:normal;font-variant-numeric:normal;font-variant-alternates:normal;font-feature-settings:normal;text-indent:0;text-align:start;text-decoration-line:none;text-decoration-style:solid;text-decoration-color:#000;text-transform:none;block-progression:tb;white-space:normal;shape-padding:0;isolation:auto;mix-blend-mode:normal;solid-color:#000;solid-opacity:1;marker:none" d="M432 393.361c0 23.173-18.835 42-42.016 42-23.18 0-42.017-18.827-42.017-42 0-23.172 18.837-42 42.017-42 23.18 0 42.016 18.828 42.016 42zm-4.002 0c0-21.01-16.994-37.998-38.014-37.998-21.019 0-38.015 16.989-38.015 37.998 0 21.01 16.996 38 38.015 38 21.02 0 38.014-16.99 38.014-38z" font-weight="400" font-family="sans-serif" overflow="visible"/><path pid="1" style="marker:none" overflow="visible" d="M397.987 396.362v-6h-32.013v6z"/><ellipse pid="2" style="marker:none" cx="-393.362" cy="-408.991" transform="matrix(0 -1 -1 0 0 0)" rx="5" ry="5.002" overflow="visible"/></g>'
  }
})
