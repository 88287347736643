/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'spinner': {
    width: 96,
    height: 96,
    viewBox: '0 0 96 96',
    data: '<path pid="0" _fill="gray" d="M11.73 69.19C22.3 87.5 44.64 94.83 63.71 87.18l-2.02-3.499c-17.16 6.603-37.01-.055-46.5-16.49a37.852 37.852 0 01-4.626-13.03l-4.032-.004a42.17 42.17 0 005.202 15.03zM6.554 42.21h4.016c1.75-10.91 8.21-20.97 18.54-26.93 10.33-5.968 22.28-6.537 32.6-2.58l2.01-3.479C52.17 4.605 38.69 5.123 27.11 11.81 15.52 18.5 8.34 29.9 6.55 42.21zm67.5 39c16.18-12.7 21.01-35.71 10.43-54.02-2.76-4.78-6.32-8.8-10.42-12.01l-2.012 3.494a37.882 37.882 0 018.969 10.51c9.49 16.44 5.323 36.96-8.982 48.52z"/>'
  }
})
