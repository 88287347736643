/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'next': {
    width: 96,
    height: 96,
    viewBox: '0 0 96 96',
    data: '<path pid="0" d="M34.134 1c4.145 5.245 8.128 10.542 11.791 15.939 4.076 6.002 7.726 11.827 10.954 17.47 2.978 5.207 5.22 9.641 7 13.59-1.78 3.95-4.021 8.385-7 13.594C53.65 67.236 50 73.06 45.925 79.063 42.262 84.46 38.278 89.755 34.134 95v-7.936c2.4-3.15 4.768-6.378 7.075-9.751a197.076 197.076 0 008.22-13.086c2.6-4.526 5.706-10.746 7.714-15.003v-.002l.576-1.22-.576-1.222v-.002C55.135 42.52 52.03 36.3 49.428 31.775a197.265 197.265 0 00-8.22-13.086 234.847 234.847 0 00-7.074-9.75V1z" _fill="gray"/>'
  }
})
