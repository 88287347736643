/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'lock': {
    width: 96,
    height: 96,
    viewBox: '0 0 96 96',
    data: '<g color="#000" _fill="gray"><path pid="0" style="line-height:normal;font-variant-ligatures:none;font-variant-position:normal;font-variant-caps:normal;font-variant-numeric:normal;font-variant-alternates:normal;font-feature-settings:normal;text-indent:0;text-align:start;text-decoration-line:none;text-decoration-style:solid;text-decoration-color:#000;text-transform:none;block-progression:tb;white-space:normal;shape-padding:0;isolation:auto;mix-blend-mode:normal;solid-color:#000;solid-opacity:1;marker:none" d="M16 44v48h64V44H16zm4 4H76v40H20V48z" font-weight="400" font-family="sans-serif" overflow="visible"/><path pid="1" style="line-height:normal;text-indent:0;text-align:start;text-decoration-line:none;text-decoration-style:solid;text-decoration-color:#000;text-transform:none;block-progression:tb;white-space:normal;isolation:auto;mix-blend-mode:normal;solid-color:#000;solid-opacity:1;marker:none" d="M48 4C34.184 4 22.998 15.18 22.998 28.99V45H27V28.99c0-11.664 9.332-20.992 21-20.992A20.905 20.905 0 0168.998 28.99V45H73V28.99C73 15.18 61.816 4 48 4z" font-weight="400" font-family="sans-serif" overflow="visible"/><path pid="2" style="marker:none" d="M44 60l8-2v20h-8z" overflow="visible"/></g>'
  }
})
