/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'buggy-full': {
    width: 96,
    height: 96,
    viewBox: '0 0 96 96',
    data: '<path pid="0" d="M47.99 10.28c-9.914.012-18.4 7.118-20.15 16.88h40.32c-1.75-9.77-10.25-16.88-20.17-16.88zM17.65 27.19c-.46 0-.923.18-1.275.532l-2.307 2.307a1.793 1.793 0 000 2.543l8.214 8.214a40.402 40.402 0 00-1.504 8.762H11.85c-.996 0-1.794.798-1.794 1.794v3.265c0 .996.797 1.794 1.794 1.794h9.05c.416 3.893 1.358 7.577 2.759 10.93l-10.11 10.11a1.796 1.796 0 000 2.543l2.307 2.314a1.793 1.793 0 002.543 0l8.667-8.672c4.304 6.29 10.43 10.63 17.39 11.74v-35.42a1.8 1.8 0 011.801-1.8h3.31a1.8 1.8 0 011.801 1.8v35.46c7.07-1.07 13.29-5.48 17.63-11.87l8.768 8.78a1.793 1.793 0 002.543 0L82.616 80a1.793 1.793 0 000-2.543l-10.23-10.24c1.373-3.32 2.294-6.96 2.705-10.81h9.056c.996 0 1.794-.797 1.794-1.794V51.35c0-.996-.797-1.794-1.794-1.794h-8.94a40.12 40.12 0 00-1.45-8.64l8.337-8.337a1.793 1.793 0 000-2.543l-2.307-2.307a1.793 1.793 0 00-2.543 0l-6.581 6.581h-45.18l-6.581-6.58a1.789 1.789 0 00-1.269-.533z" _stroke="#111" stroke-linecap="round" _fill="#111"/>'
  }
})
