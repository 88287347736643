/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'back': {
    width: 96,
    height: 96,
    viewBox: '0 0 96 96',
    data: '<path pid="0" d="M61.866 1c-4.145 5.245-8.128 10.542-11.791 15.939-4.076 6.002-7.726 11.827-10.954 17.47-2.978 5.207-5.22 9.641-7 13.59 1.78 3.95 4.021 8.385 7 13.594C42.35 67.236 46 73.06 50.075 79.063 53.738 84.46 57.722 89.755 61.866 95v-7.936c-2.4-3.15-4.768-6.378-7.075-9.751a197.076 197.076 0 01-8.22-13.086c-2.6-4.526-5.706-10.746-7.714-15.003v-.002l-.576-1.22.576-1.222v-.002c2.008-4.257 5.113-10.477 7.715-15.003a197.265 197.265 0 018.22-13.086 234.847 234.847 0 017.074-9.75V1z" _fill="gray"/>'
  }
})
