/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'github': {
    width: 96,
    height: 96,
    viewBox: '0 0 96 96',
    data: '<path pid="0" style="isolation:isolate" _fill="gray" d="M48.01 5.996c-23.19 0-42 19.28-42 43.07 0 19.02 12.03 35.16 28.72 40.86 2.1.395 2.866-.933 2.866-2.074 0-1.023-.035-3.732-.056-7.324-11.68 2.602-14.15-5.774-14.15-5.774-1.911-4.974-4.662-6.298-4.662-6.298-3.815-2.673.287-2.616.287-2.616 4.214.305 6.433 4.439 6.433 4.439 3.745 6.581 9.831 4.679 12.22 3.578.381-2.781 1.466-4.679 2.667-5.756-9.327-1.087-19.13-4.78-19.13-21.28 0-4.701 1.638-8.544 4.323-11.56-.43-1.091-1.873-5.469.413-11.4 0 0 3.528-1.159 11.55 4.414a39.357 39.357 0 0110.51-1.45c3.567.016 7.161.495 10.51 1.45 8.018-5.573 11.54-4.414 11.54-4.414 2.293 5.928.85 10.31.416 11.4 2.692 3.011 4.316 6.854 4.316 11.56 0 16.54-9.82 20.18-19.18 21.25 1.51 1.328 2.852 3.958 2.852 7.974 0 5.756-.052 10.4-.052 11.81 0 1.152.756 2.49 2.887 2.071 16.68-5.709 28.7-21.84 28.7-40.86 0-23.78-18.81-43.07-42-43.07z"/>'
  }
})
