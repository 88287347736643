/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'user-admin': {
    width: 96,
    height: 96,
    viewBox: '0 0 96 96',
    data: '<g transform="translate(67.857 -78.505)" color="#000" _fill="gray"><path pid="0" style="line-height:normal;font-variant-ligatures:normal;font-variant-position:normal;font-variant-caps:normal;font-variant-numeric:normal;font-variant-alternates:normal;font-feature-settings:normal;text-indent:0;text-align:start;text-decoration-line:none;text-decoration-style:solid;text-decoration-color:#000;text-transform:none;text-orientation:mixed;white-space:normal;shape-padding:0;isolation:auto;mix-blend-mode:normal;solid-color:#000;solid-opacity:1;marker:none" d="M-43.857 102.505c-13.231 0-24 10.769-24 24s10.769 24 24 24h.002c9.394-.01 17.826-5.543 21.719-14H-9.5l3.715-4.1 3.928 3.928 4-4 4 4 4-4 6 6 2.414-2.414 9.414-9.414-10-10h-40.086c-3.896-8.465-12.34-14-21.742-14zm0 4c8.244 0 15.63 5.046 18.629 12.727l.496 1.273h41.047l6 6-6.172 6.172-6-6-4 4-4-4-4 4-4.072-4.072-5.344 5.9h-13.48l-.497 1.273a19.986 19.986 0 01-18.607 12.727c-11.07 0-20-8.93-20-20s8.93-20 20-20z" font-weight="400" font-family="sans-serif" overflow="visible"/><circle pid="1" style="marker:none" cx="-51.857" cy="126.505" r="6" overflow="visible"/></g>'
  }
})
