/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ubuntuone': {
    width: 90,
    height: 90,
    viewBox: '0 0 90 90',
    data: '<path pid="0" d="M12 3l.202 55.257s-.29 23.16 24.799 28.04C51.43 89.105 79 82.595 79 82.595V3H56v59H35V26H26.05L38.206 3z" _fill="gray"/>'
  }
})
