/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'external-link': {
    width: 96,
    height: 96,
    viewBox: '0 0 96 96',
    data: '<g color="#000" _fill="gray"><path pid="0" style="line-height:normal;font-variant-ligatures:none;font-variant-position:normal;font-variant-caps:normal;font-variant-numeric:normal;font-variant-alternates:normal;font-feature-settings:normal;text-indent:0;text-align:start;text-decoration-line:none;text-decoration-style:solid;text-decoration-color:#000;text-transform:none;white-space:normal;shape-padding:0;isolation:auto;mix-blend-mode:normal;solid-color:#000;solid-opacity:1;marker:none" d="M23.988 27.999l-.011.002c-5.033.058-8.714-.12-11.725 1.54-1.505.832-2.697 2.236-3.355 3.991C8.237 35.287 8 37.368 8 40V76c0 2.63.238 4.712.897 6.467.658 1.754 1.85 3.16 3.355 3.99 3.011 1.661 6.692 1.485 11.725 1.543h28.046c5.033-.058 8.713.118 11.724-1.543 1.506-.83 2.697-2.236 3.356-3.99.658-1.755.896-3.837.896-6.467V48h-4v28c0 2.37-.25 4.02-.64 5.06s-.81 1.49-1.543 1.895c-1.465.808-4.778.986-9.817 1.045H24c-5.038-.059-8.352-.237-9.816-1.045-.734-.405-1.151-.855-1.541-1.895-.39-1.04-.643-2.69-.643-5.06V39.999c0-2.37.253-4.021.643-5.06.39-1.04.807-1.49 1.54-1.895 1.465-.808 4.779-.986 9.817-1.045L48 32v-4l-24.012-.002z" font-weight="400" font-family="sans-serif" overflow="visible"/><path pid="1" style="line-height:normal;font-variant-ligatures:none;font-variant-position:normal;font-variant-caps:normal;font-variant-numeric:normal;font-variant-alternates:normal;font-feature-settings:normal;text-indent:0;text-align:start;text-decoration-line:none;text-decoration-style:solid;text-decoration-color:#000;text-transform:none;white-space:normal;shape-padding:0;isolation:auto;mix-blend-mode:normal;solid-color:#000;solid-opacity:1;marker:none" d="M72.586 20.587l-30 30 2.828 2.828 30-30z" font-weight="400" font-family="sans-serif" overflow="visible"/><path pid="2" style="marker:none" d="M63.958 15.07l16.976 16.965a177.471 177.471 0 003.925-11.842A211.643 211.643 0 0088 8c-3.985.936-8.035 2-12.147 3.187a206.813 206.813 0 00-11.894 3.882z" overflow="visible"/></g>'
  }
})
