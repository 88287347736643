/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'toolkit_arrow-up': {
    width: 96,
    height: 96,
    viewBox: '0 0 96 96',
    data: '<g _fill="gray"><path pid="0" style="line-height:125%;-inkscape-font-specification:Ubuntu;text-align:center" d="M72.52 22.102c-.022-.017-.036-.04-.057-.057-.025-.02-.057-.03-.082-.05zM23.576 21.98l-.139.108c.026-.02.057-.03.083-.05.021-.017.035-.041.056-.057z" font-weight="400" font-size="15" font-family="Ubuntu" letter-spacing="0" word-spacing="0" text-anchor="middle"/><path pid="1" style="marker:none" d="M92 85.714H4.002S24.776 40.932 47.999 10.29C71.222 40.932 92 85.714 92 85.714z" color="#000" overflow="visible"/></g>'
  }
})
