/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'gitlab': {
    width: 96,
    height: 96,
    viewBox: '0 0 96 96',
    data: '<path pid="0" style="isolation:isolate" _fill="gray" d="M72.89 7.68a1.921 1.921 0 00-1.846 1.327l-9.513 29.4h-27.07l-9.513-29.4a1.93 1.93 0 00-1.779-1.327 1.925 1.925 0 00-1.836 1.245l-11.46 30.68c-.008 0-.008.008-.015.013l-5.74 15.37c-.3.81-.024 1.722.674 2.228l42.07 30.72.008.016c.007 0 .007 0 .007.008l.077.044c.029.015.05.038.08.051.008 0 .008.008.015.008 0 0 .008.008.016.008.007.008.015.016.028.016l.031.023c.038.013.082.038.126.052v.008l.129.044c.007 0 .007 0 .015.008a.705.705 0 01.095.03c.016 0 .031.008.054.008.038.016.075.021.111.03l.15.022c.076 0 .156.008.233.008.074-.008.141-.008.208-.023.03 0 .052 0 .082-.008h.016c.015-.008.02-.008.038-.008.006 0 .013-.006.029-.006.015-.008.038-.008.051-.015l.077-.023c.013 0 .02 0 .029-.008h.023a1.24 1.24 0 00.195-.083c.005 0 .005 0 .013-.008.008 0 .015 0 .023-.008l.044-.02a1.821 1.821 0 00.15-.09c.007 0 .014-.009.022-.009.03-.023.06-.038.09-.06l.015-.023 42.07-30.71a1.93 1.93 0 00.664-2.228l-5.724-15.31a.141.141 0 01-.015-.053l-11.48-30.58a1.91 1.91 0 00-1.763-1.25zM15.61 42.21h16.08l11.2 34.6zm48.71 0H80.4l-27.28 34.6zm-52.06 1.936l25.59 32.45-29.62-21.63zm71.5 0l4.034 10.82-29.62 21.63z"/>'
  }
})
