/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'security-alert': {
    width: 96,
    height: 96,
    viewBox: '0 0 96 96',
    data: '<g color="#000" _fill="#ed3146"><path pid="0" style="marker:none" d="M45 30v9.344c0 2.746.094 5.29.28 7.634.186 2.31.435 4.638.745 6.983h3.996c.28-2.345.513-4.673.7-6.983.185-2.311.279-4.855.279-7.634V30h-6zM48.016 58c-1.096 0-2.043.386-2.84 1.158-.763.738-1.144 1.692-1.144 2.866 0 1.174.38 2.13 1.144 2.868.797.738 1.744 1.108 2.84 1.108 1.129 0 2.076-.37 2.84-1.108.763-.738 1.144-1.694 1.144-2.868s-.381-2.128-1.145-2.866c-.763-.772-1.71-1.158-2.84-1.158z" overflow="visible"/><path pid="1" style="marker:none" d="M48 2s-9.818 16.43-36 16.43C12 74.287 48 94 48 94s36-19.713 36-75.57C57.818 18.43 48 2 48 2zm0 6.295l2.373 2.17c1.116 1.02 2.242 2 3.553 2.94 5.038 3.613 12.715 7.121 22.82 8.357l3.28.398-.192 3.3c-1.347 23.145-9.149 39.029-16.932 49.33-5.319 7.039-9.729 10.77-12.832 13.028L48 89.324l-2.068-1.506h-.002c-3.103-2.258-7.513-5.99-12.832-13.029-7.783-10.3-15.585-26.184-16.932-49.328l-.191-3.3 3.279-.4c10.105-1.235 17.782-4.743 22.82-8.357 1.31-.94 2.437-1.919 3.553-2.94l.687-.628L48 8.295z" overflow="visible"/></g>'
  }
})
