/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'neutral': {
    width: 96,
    height: 96,
    viewBox: '0 0 96 96',
    data: '<path pid="0" d="M54.87 37.21q0-2.021 1.405-3.392 1.405-1.405 3.392-1.405t3.392 1.405q1.439 1.37 1.439 3.392 0 1.987-1.439 3.426-1.405 1.405-3.392 1.405-2.021 0-3.426-1.405-1.37-1.439-1.37-3.426zM31.48 37.21q0-2.021 1.405-3.392 1.405-1.405 3.392-1.405t3.392 1.405q1.439 1.37 1.439 3.392 0 1.987-1.439 3.426-1.405 1.405-3.392 1.405-2.021 0-3.426-1.405-1.37-1.439-1.37-3.426z" _fill="#111"/><circle pid="1" _stroke="#111" stroke-dashoffset="29.48" cy="48" cx="47.99" stroke-width="4" _fill="none" r="33.97"/><path pid="2" _fill="#111" d="M27.69 59.17h40.63v4.004H27.69z"/>'
  }
})
