<template>
  <div class="p-strip">
    <div class="row">
      <h1 v-translate>Page Not Found</h1>

      <h2 v-translate>The page you are looking for has been removed or does not exist</h2>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotFound',
};
</script>
