/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'neutral-full': {
    width: 96,
    height: 96,
    viewBox: '0 0 96 96',
    data: '<path pid="0" _fill="#111" d="M47.99 14.03A33.97 33.97 0 0014.02 48a33.97 33.97 0 0033.97 33.97A33.97 33.97 0 0081.96 48a33.97 33.97 0 00-33.97-33.97zM36.28 32.41c1.32 0 2.45.47 3.39 1.41.96.91 1.44 2.04 1.44 3.39 0 1.33-.48 2.47-1.44 3.43-.94.93-2.07 1.4-3.39 1.4-1.35 0-2.49-.47-3.43-1.4-.91-.96-1.37-2.1-1.37-3.43 0-1.35.47-2.48 1.4-3.39.94-.94 2.07-1.41 3.4-1.41zm23.39 0c1.32 0 2.45.47 3.39 1.41.96.91 1.44 2.04 1.44 3.39 0 1.33-.48 2.47-1.44 3.43-.94.93-2.07 1.4-3.39 1.4-1.35 0-2.49-.47-3.43-1.4-.91-.96-1.37-2.1-1.37-3.43 0-1.35.47-2.48 1.41-3.39.93-.94 2.06-1.41 3.39-1.41zM27.69 59.17h40.63v4H27.69v-4z"/>'
  }
})
